import { FC } from "react";
import { ToastContainer, ToastContainerProps } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const NotificationService: FC<ToastContainerProps> = ({ children }) => {
  return (
    <ToastContainer
      position="bottom-center"
      hideProgressBar={false}
      theme="dark"
      autoClose={5000}
      newestOnTop={false}
      closeOnClick
      rtl={false}
    >
      {children}
    </ToastContainer>
  );
};
