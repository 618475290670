import { FC, PropsWithChildren } from "react";

/* Components */
import { ErrorBoundary } from "components/common";

/* Services */
import { RouterService } from "services/router/RouterService";
import { AuthService } from "services/auth/AuthService";
import { ReactQueryService } from "services/reactQuery/ReactQuery";
import { NotificationService } from "services/notification/NotificationService";

type ServiceProvidersProps = PropsWithChildren<{}>;

const AppProviders: FC = ({ children }: ServiceProvidersProps) => {
  return (
    <ErrorBoundary>
      <ReactQueryService>
        <RouterService>
          <NotificationService />
          <AuthService>{children}</AuthService>
        </RouterService>
      </ReactQueryService>
    </ErrorBoundary>
  );
};

export default AppProviders;
