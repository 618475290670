import { lazy } from "react";
import { RouteObject } from "react-router-dom";

/* Gaurds */
import { AuthGuard } from "components/common";

/* Pages */
const Dashboard = lazy(() => import("pages/Dashboard"));
const Roles = lazy(() => import("pages/Roles"));
const Login = lazy(() => import("pages/Login"));
const Users = lazy(() => import("pages/Users"));
const Faqs = lazy(() => import("pages/Faqs"));
const Localizations = lazy(() => import("pages/Localizations"));
const Categories = lazy(() => import("pages/Categories"));
const Specifications = lazy(() => import("pages/Specifications"));
const Brands = lazy(() => import("pages/Brands"));
const Products = lazy(() => import("pages/Products"));
const Tags = lazy(() => import("pages/Tags"));
const Warranties = lazy(() => import("pages/Warranties"));
const Colors = lazy(() => import("pages/Colors"));
const Testimonials = lazy(() => import("pages/Testimonials"));
const Problems = lazy(() => import("pages/Problems"));
const Coupons = lazy(() => import("pages/Coupons"));
const Orders = lazy(() => import("pages/Orders"));
const ProductSections = lazy(() => import("pages/ProductSections"));
const BlogPosts = lazy(() => import("pages/BlogPosts"));
const BlogCategories = lazy(() => import("pages/BlogCategories"));
const BlogTags = lazy(() => import("pages/BlogTags"));
const MenuLinks = lazy(() => import("pages/MenuLinks"));
const CouponUses = lazy(() => import("pages/CouponUses"));

const routes: RouteObject[] = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <Dashboard />
      </AuthGuard>
    ),
    children: [
      { path: "users", element: <Users /> },
      { path: "roles", element: <Roles /> },
      { path: "faqs", element: <Faqs /> },
      { path: "localizations", element: <Localizations /> },
      { path: "categories", element: <Categories /> },
      { path: "brands", element: <Brands /> },
      { path: "specifications", element: <Specifications /> },
      { path: "products", element: <Products /> },
      { path: "orders", element: <Orders /> },
      { path: "tags", element: <Tags /> },
      { path: "warranties", element: <Warranties /> },
      { path: "colors", element: <Colors /> },
      { path: "testimonials", element: <Testimonials /> },
      { path: "problems", element: <Problems /> },
      { path: "coupons", element: <Coupons /> },
      { path: "product-sections", element: <ProductSections /> },
      { path: "blog-posts", element: <BlogPosts /> },
      { path: "blog-categories", element: <BlogCategories /> },
      { path: "blog-tags", element: <BlogTags /> },
      { path: "menu-links", element: <MenuLinks /> },
      { path: "coupon-uses", element: <CouponUses /> },
    ],
  },
  { path: "/login", element: <Login /> },
  { path: "*", element: <div>NOT FOUND</div> },
];

export default routes;
