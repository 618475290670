import { FC, useState } from "react";
import { ThemeSwitcher } from "components/common";
import { ActionIcon, SingleSelect } from "components/ui";
import { HiOutlineMenu, HiBell, HiMail } from "react-icons/hi";
import { UserCard } from "components/dashboard";
import { useTranslation } from "react-i18next";
// @ts-ignore
import ReactCountryFlag from "react-country-flag";

export const Header: FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="lg:block text-right flex items-center justify-between p-[0.7rem] bg-secondary-light-100 dark:bg-secondary-dark-100">
      <ActionIcon className="lg:hidden">
        <label htmlFor="dashboard-drawer">
          <HiOutlineMenu className="text-2xl" />
        </label>
      </ActionIcon>
      <div className="flex items-center space-x-2 w-fit rtl:mr-auto rtl:ml-0 ml-auto">
        <ThemeSwitcher />
        <ActionIcon>
          <HiMail className="text-2xl" />
        </ActionIcon>
        <ActionIcon>
          <HiBell className="text-2xl" />
        </ActionIcon>
        <select
          className="dark:select"
          onChange={(e) => {
            changeLanguage(e.target.value);
          }}
        >
          <option
            selected={localStorage.getItem("i18nextLng") == "en"}
            value="en"
          >
            EN
          </option>
          <option
            selected={localStorage.getItem("i18nextLng") == "fa"}
            value="fa"
          >
            FA
          </option>
        </select>

        <UserCard
          userName="admin"
          firstName="Root"
          lastName="Admin"
          imageUrl=""
          isActive={true}
          id="123"
          phoneNumber="123"
          email="ad"
        />
      </div>
    </div>
  );
};
