import { post } from "config/api";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { get } from "config/api";

type Provider = {
  children: JSX.Element | JSX.Element[];
};

export const AuthContext = React.createContext({});

export const AuthProvider = ({ children }: Provider) => {
  const user = useProvideAuth();

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

export const useAuth = (): any => useContext(AuthContext);

const useProvideAuth = () => {
  const [token, setToken] = useState<string | null>(null);
  let navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      (async () => {
        try {
          const xtoken = await localStorage.getItem("token");
          await post(
            "/identity/verify",
            {},
            {
              headers: { Authorization: `Bearer ${xtoken}` },
            }
          );
          setToken(xtoken);
        } catch (err) {
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
      })();
    }
    const localToken = localStorage.getItem("token");
    setToken(localToken);
  }, []);

  const login = async (token: string) => {
    if (!token) return;
    localStorage.setItem("token", token);
    setToken(token);
    window.location.assign("/");
  };

  const logout = () => {
    if (!token) return;
    localStorage.removeItem("token");
    setToken(null);
    window.location.assign("/login");
  };

  return {
    token,
    login,
    logout,
  };
};
