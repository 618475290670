import { createRoot } from "react-dom/client";
import App from "./App";

/* services */
import reportWebVitals from "services/webVitals/reportWebVitals";
import AppProviders from "services";

const root = createRoot(document.getElementById("root"));

root.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById("root")
);

reportWebVitals();
