import { FC, ReactNode, ButtonHTMLAttributes } from "react";
import classNames from "classnames";

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  loading?: boolean;
}

export const Button: FC<IButton> = ({
  children,
  className,
  loading,
  ...rest
}) => {
  return (
    <button
      className={classNames(
        className,
        `btn bg-primary hover:bg-primary text-white border-0 rounded-lg px-4 min-h-[2rem] h-[2.5rem] ${
          loading ? "loading opacity-75 pointer-events-none" : ""
        }`
      )}
      {...rest}
    >
      {children}
    </button>
  );
};
