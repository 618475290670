import logo from "assets/images/logo.svg";
import { ActionIcon } from "components/ui";
import { useAuth, useStore } from "hooks";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  HiCollection,
  HiCube,
  HiKey,
  HiOutlineLogout,
  HiShoppingBag,
  HiUser,
  HiLightBulb,
  HiViewGrid,
  HiLightningBolt,
  HiTag,
  HiColorSwatch,
  HiChat,
  HiClipboardCopy,
} from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";

export const Sidebar: FC = () => {
  const { logout } = useAuth();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const store = useStore((store) => store);

  const items = [
    {
      title: t("dashboard"),
      path: "/",
      icon: <HiViewGrid className="text-2xl" />,
    },
    {
      title: t("users"),
      path: "/users",
      icon: <HiUser className="text-2xl" />,
    },
    { title: t("roles"), path: "/roles", icon: <HiKey className="text-2xl" /> },
    {
      title: t("faqs"),
      path: "/faqs",
      icon: <HiLightBulb className="text-2xl" />,
    },
    {
      title: t("products"),
      path: "/products",
      icon: <HiShoppingBag className="text-2xl" />,
    },
    {
      title: t("orders"),
      path: "/orders",
      icon: <HiCollection className="text-2xl" />,
    },
    {
      title: t("categories"),
      path: "/categories",
      icon: <HiCollection className="text-2xl" />,
    },
    {
      title: t("brands"),
      path: "/brands",
      icon: <HiLightningBolt className="text-2xl" />,
    },
    {
      title: t("specifications"),
      path: "/specifications",
      icon: <HiCube className="text-2xl" />,
    },
    {
      title: t("tags"),
      path: "/tags",
      icon: <HiTag className="text-2xl" />,
    },
    {
      title: t("product_sections"),
      path: "/product-sections",
      icon: <HiCube className="text-2xl" />,
    },
    {
      title: t("testimonials"),
      path: "/testimonials",
      icon: <HiChat className="text-2xl" />,
    },
    {
      title: t("coupons"),
      path: "/coupons",
      icon: <HiClipboardCopy className="text-2xl" />,
    },
    {
      title: t("coupon_uses"),
      path: "/coupon-uses",
      icon: <HiClipboardCopy className="text-2xl" />,
    },
    {
      title: t("blog_posts"),
      path: "/blog-posts",
      icon: <HiColorSwatch className="text-2xl" />,
    },
    {
      title: t("blog_categories"),
      path: "/blog-categories",
      icon: <HiColorSwatch className="text-2xl" />,
    },
    {
      title: t("blog_tags"),
      path: "/blog-tags",
      icon: <HiColorSwatch className="text-2xl" />,
    },
    {
      title: t("menu_links"),
      path: "/menu-links",
      icon: <HiColorSwatch className="text-2xl" />,
    },
  ];

  return (
    <div className={`drawer-side ${store.menuStatus ? "-z-20" : ""}`}>
      <label htmlFor="dashboard-drawer" className="drawer-overlay"></label>
      <ul className="flex flex-col justify-between overflow-y-auto w-72 dark:bg-secondary-dark-100 bg-secondary-light-100">
        <div>
          <li className="mb-5 p-8 space-y-5">
            <img src={logo} alt="logo" className="w-36 h-10" />
          </li>
          <div className="space-y-7">
            {items.map((item) => (
              <Link to={item.path} key={item.path} className="block">
                <li
                  onClick={() => {
                    document.getElementById("dashboard-drawer")?.click();
                  }}
                  className={`relative flex items-center text-lg py-2 ${
                    pathname === item.path
                      ? "opacity-100 text-[#4fb769]"
                      : " opacity-70"
                  } hover:opacity-100 transition-all duration-200`}
                >
                  {pathname === item.path && (
                    <span className="absolute left-0 rtl:right-0 rtl:left-auto inset-y-0 border-[3px] border-primary" />
                  )}
                  <div className="flex items-center rtl:space-x-reverse space-x-3 pl-8 rtl:pr-8">
                    {item.icon}
                    <span>{item.title}</span>
                  </div>
                </li>
              </Link>
            ))}
          </div>
        </div>

        <div className="p-8">
          <ActionIcon
            onClick={logout}
            className="mt-5 w-fit flex items-center rtl:space-x-reverse space-x-3 dark:hover:bg-transparent"
          >
            <HiOutlineLogout className="text-2xl text-red-500" />
            <span className="text-lg text-red-500 font-semibold rtl:font-rtl-semibold">
              {t("logout")}
            </span>
          </ActionIcon>
        </div>
      </ul>
    </div>
  );
};
