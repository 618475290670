import { FC, useEffect } from "react";

import "antd/dist/antd.css";
import "styles/app.css";

import { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { Loading } from "components/common";
import { useDarkMode } from "hooks";
import routes from "routes";
import { AppLayout } from "components/layouts";

import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "locales/en/translation.json";
import fa from "locales/fa/translation.json";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: en,
      },
      fa: {
        translation: fa,
      },
    },
    lng: "fa",
    fallbackLng: "fa",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage"],
      caches: ["localStorage"],
      htmlTag: document.documentElement,
    },
  });

const App: FC = () => {
  const { i18n } = useTranslation();

  useDarkMode();
  const content = useRoutes(routes);

  useEffect(() => {
    if (i18n.language == "fa") {
      document.body.setAttribute("dir", "rtl");
    } else {
      document.body.setAttribute("dir", "ltr");
    }
  }, [i18n.language]);

  return (
    <AppLayout>
      <Suspense fallback={<Loading />}>{content}</Suspense>
    </AppLayout>
  );
};

export default App;
